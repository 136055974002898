<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="auto" persistent>
      <v-card class="dialog-card-upload pa-5">
        <v-card-title class="primary--text mb-1">
          Documentos
        </v-card-title>

        <div v-if="isFileData" class="file-input-container mb-6 mx-5">
          <div class="content">
            <div class="custom-file-input d-flex align-center"
              @dragover.prevent
              @drop="handleDrop"
              @dragenter="handleDragEnter"
              @dragleave="handleDragLeave"
            >
              <div class="file-input-inner d-flex">
                <v-icon color="primary" size="18" class="file-input-inner-mr">fas fa-file-upload</v-icon>
                <div v-if="isHandleDragEnter" class="file-input-label">{{ uploadMessage }}</div>
                <div v-else class="file-input-label">
                  {{ uploadMessage }}
                  <span class="message-color"> clique para procurar no seu dispositivo </span>
                </div>
              </div>
              <input type="file" multiple @change="handleFileChange" ref="fileInput" accept=".pdf, .jpeg, .jpg, .png, .bmp, .gif, .svg, .tiff, .doc, .docx, .txt, .csv, .xls, .xlsx, .zip, .rar" />
            </div>
          </div>
        </div>

        <div class="mb-5 mx-5">
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="isMovementRecord ? headerDocumentsMovementRecord : headerDocumentsFileData"
                :items="documents"
                item-key="id"
                class="elevation-1"
                v-model="selectedItems"
                :show-select="documents && documents.length > 0 ? true : false"
                :page="page"
                :items-per-page="itemsPerPage"
                :footer-props="footerProps"
                :key="dataTableKey"
                @update:page="changePageAndUncheckItems($event, 'PAGE')"
                @update:items-per-page="changePageAndUncheckItems($event, 'ITEMS')"
                :height="documents && documents.length > 5 ? '288px' : ''"
              >
                <template v-slot:[`header.actions`]="{ header }">
                  <span>{{ header.text }}</span>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <span class="align-left"> {{ item.name ? item.name : '-' }} </span>
                </template>
                <template v-if="isMovementRecord" v-slot:[`item.documentType`]="{ item }">
                  {{ item.documentType != null ? item.documentType.name : '-' }}
                </template>
                <template v-slot:[`item.size`]="{ item }">
                  {{ item.size ? convertBytesToSize(item.size) : '-' }}
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                  {{ item.createdAt ? formatter.formatDateTimeZoneWithHours(item.createdAt) : '-' }}
                </template>
                <template v-slot:[`item.userName`]="{ item }">
                  {{ item.userName ? item.userName : '-'}}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>

        <v-card-actions class="pa-0 mb-3 mx-5">
          <v-btn color="primary" large outlined @click="close()" class="ma-1">
            <v-icon class="mr-2" size="20">
              fas fa-chevron-left
            </v-icon>
            VOLTAR
          </v-btn>
          <v-row
            no-gutters
            class="d-flex justify-end">
            <v-btn
              v-if="isFileData && hasProtocol"
              @click="findFiles()"
              color="primary"
              large
              :class="actionBtnClass">
              <v-icon class="mr-1" size="20">
                fas fa-search
              </v-icon>
              BUSCAR ARQUIVOS NO DIRETÓRIO
            </v-btn>
            <v-btn
              v-if="isFileData"
              @click="findMovementFiles()"
              color="primary"
              large
              :class="actionBtnClass">
              <v-icon class="mr-1" size="20">
                fas fa-search-plus
              </v-icon>
              ADICIONAR ANEXOS
            </v-btn>
            <v-btn
              v-if="!isFileData && hasProtocol"
              color="primary"
              large
              @click="findProtocolFiles()"
              :class="actionBtnClass">
              <v-icon class="mr-1" size="20">
                fas fa-search
              </v-icon>
              BUSCAR ANEXOS DO LAYOUT
            </v-btn>
            <v-btn v-if="isFileData" color="primary" large :class="actionBtnClass" @click="openDeleteSelectedDocuments()">
              <v-icon class="mr-2" size="20">
                fas fa-times
              </v-icon>
              EXCLUIR DOCUMENTOS
            </v-btn>
            <v-btn color="primary" :class="actionBtnClass" large @click="openDownloadSelectedDocuments()" class="ma-1 px-2">
              <v-icon class="mr-1" size="20">
                fas fa-download
              </v-icon>
              BAIXAR DOCUMENTOS
            </v-btn>
          </v-row>
        </v-card-actions>

        <div class="loading-overlay" v-if="loadingActionDynamicModal">
          <v-progress-circular color="primary" indeterminate size="120"/>
        </div>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>

    <ExclusionDynamicModal :show="showDialogDynamic" @close="closeDynamicDialog" @confirm="confirmDynamicDialog">
      <template slot="header"> {{ titleDynamicDialog }} </template>
      <template slot="content">
        {{ contentDynamicDialog }}
      </template>
    </ExclusionDynamicModal>

    <DynamicDialogInformation ref="DynamicDialogInformation" :widthModalValue="800" :heightCardValue="800">
      <div slot="title">
        <v-row>
          <v-col cols="12">
            <span color="primary"> Erros durante o upload </span>
          </v-col>
        </v-row>
      </div>
      <div slot="textInfo">
        <v-row>
          <v-col cols="12">
            <span> Por favor verifique os erros abaixo: </span>
            <div v-for="(error, index) in validationsErros" :key="index">
              <h5 class="mt-2 font-weight-regular" style="word-break: break-word"> <span class="red--text"> {{ '* ' }} </span> {{ error }}</h5>
            </div>
          </v-col>
        </v-row>
      </div>
    </DynamicDialogInformation>
  </div>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import DocumentService from '@/services-http/sdi/DocumentService';
import ExclusionDynamicModal from '@/components/ExclusionDynamicModal/ExclusionDynamicModal.vue';
import DynamicDialogInformation from '@/components/DynamicDialogInformation/DynamicDialogInformation.vue';
import Rules from '@/shared/validators/formRules';
export default ({
  name: 'UploadMovementsModal',
  components: { ExclusionDynamicModal, DynamicDialogInformation },
  data: () => ({
    dialog: false,
    typeViewId: null,
    documents: [],
    uploadMessage: 'Arraste e solte os arquivos aqui ou',
    isHandleDragEnter: false,
    files: [],
    loadingDocuments: false,
    page: 1,
    itemsPerPage: 5,
    footerProps: {
      itemsPerPageOptions: [5, 10, 25, 50, -1],
      // eslint-disable-next-line object-curly-newline
    },
    actionBtnClass: "ma-1 px-2 text-caption font-weight-bold",
    headerDocumentsFileData: [
      {
        text: 'Nome Arquivo',
        value: 'name',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
        width: '50%',
      },
      {
        text: 'Tamanho',
        value: 'size',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Criado',
        value: 'createdAt',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Usuário',
        value: 'userName',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
    ],
    headerDocumentsMovementRecord: [
      {
        text: 'Nome Arquivo',
        value: 'name',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
        width: '50%',
      },
      {
        text: 'Documento',
        value: 'documentType',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Tamanho',
        value: 'size',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Criado',
        value: 'createdAt',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Usuário',
        value: 'userName',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
    ],
    selectedItems: [],
    dataTableKey: 0,
    loadingActionDynamicModal: false,
    showDialogDynamic: false,
    snackbar: {
      show: false,
      type: '',
      text: '',
      timeout: 10000,
    },
    protocolNumber: null,
    titleDynamicDialog: null,
    contentDynamicDialog: null,
    typeDynamicAction: null,
    validationsErros: [],
    isFileData: false,
    isMovementRecord: false,
    hasProtocol: false
  }),

  methods: {
    open(item, type) {
      switch (type) {
        case 'PROTOCOL':
          if (item && item.fileDataId && item.documents) {
            this.typeViewId = item.fileDataId;
            this.documents = item.documents;
            this.isFileData = true;
            this.isMovementRecord = false;
            this.dialog = true;
            this.protocolNumber = item.protocolNumber;
            this.hasProtocol = Boolean(this.protocolNumber);
          }
          break;
        case 'MOVEMENT':
          if (item && item.familyGroupId && item.documents) {
            this.typeViewId = item.movementRecordId;
            this.documents = item.documents;
            this.isFileData = false;
            this.isMovementRecord = true;
            this.dialog = true;
            this.protocolNumber = item.protocolNumber;
            this.hasProtocol = Boolean(this.protocolNumber);
          }
          break;
        default:
          this.dialog = false;
          break;
      }
    },
    close() {
      this.dataTableKey += 1;
      this.itemsPerPage = 5;
      this.selectedItems = [];
      this.$emit('verifyCountDocuments', this.documents.length);
      this.protocolNumber = null;
      this.hasProtocol = false;
      this.dialog = false;
    },
    async handleFileChange(event) {
      if (event && event.target && event.target.files) {
        this.validationsErros = [];
       await this.validationAndUpdate(event.target.files, event);

      }
    },
    async handleDrop(event) {
      if (event && event.dataTransfer && event.dataTransfer.files) {
        event.preventDefault();
        this.handleDragLeave();
        await this.validationAndUpdate(event.dataTransfer.files, event);
      }
    },
    handleDragEnter(event) {
      event.preventDefault();
      this.uploadMessage = 'Solte os arquivos aqui';
      this.isHandleDragEnter = true;
    },
    handleDragLeave() {
      this.uploadMessage = 'Arraste e solte os arquivos aqui ou';
      this.isHandleDragEnter = false;
    },
    resetTarget(event){
      event.target.value = '';
    },
    async validationAndUpdate(selectedFiles, ev) {
      const files = Array.from(selectedFiles);
      this.validationsErros = [];
      if(files.length === 0){
        this.resetTarget(ev)
        return;
      }
      this.validationsErros = await this.validateSizeAndFileExtension(files);
      if (this.validationsErros.length > 0) {
        this.resetTarget(ev)
        this.$refs.DynamicDialogInformation.open();
      } else {
        await this.uploadDocuments(files);
        this.resetTarget(ev)
      }
    },
    async uploadDocuments(files) {
      this.files = files;
      this.loadingActionDynamicModal = true;
      await this.documentService.UploadDocumentByFileDataId(this.typeViewId, this.files).then(async () => {
        this.files = [];
        await this.findDocumentsByFilters();
        this.loadingActionDynamicModal = false;
        this.customizeSnackbarMessage('success', 'Os arquivos foram enviados com sucesso.');
      }).catch(() => {
        this.loadingActionDynamicModal = false;
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao tentar efetuar o upload dos arquivos.');
      }).finally(() => {
        this.loadingActionDynamicModal = false;
      });
    },
    async validateSizeAndFileExtension(files) {
      const allowedExtensions = ['pdf', 'jpeg', 'jpg', 'png', 'bmp', 'gif', 'svg', 'tiff', 'doc', 'docx', 'txt', 'csv', 'xls', 'xlsx', 'zip', 'rar'];
      const extensionErrors = [];
      await files.forEach((file) => {
        const fileName = file.name;
        const lastDotIndex = fileName.lastIndexOf('.');

        let message;
        if (!this.rules.isValidFileSize(file.size)) {
          message = `O arquivo ${fileName} ultrapassa o limite permitido de ${this.rules.getMaxFileSizeMB()} .`;
          extensionErrors.push(message);
        }

        if (lastDotIndex === -1) {
          message = `O arquivo ${fileName} é inválido, pois o mesmo não possui extensão`;
          extensionErrors.push(message);
        }

        const fileExtension = fileName.substring(lastDotIndex + 1).toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
          message = `A extensão do arquivo ${fileName} não é valida. As extensões permitidas são: ${allowedExtensions.join(', ')}`;
          extensionErrors.push(message);
        }
      });

      return extensionErrors;
    },
    convertBytesToSize(bytes) {
      const kilobyte = 1024;
      const megabyte = kilobyte * 1024;
      const gigabyte = megabyte * 1024;

      if (bytes < kilobyte) {
        return `${bytes} bytes`;
      }

      if (bytes < megabyte) {
        return `${(bytes / kilobyte).toFixed(2)} KB`;
      }

      if (bytes < gigabyte) {
        return `${(bytes / megabyte).toFixed(2)} MB`;
      }

      return `${(bytes / gigabyte).toFixed(2)} GB`;
    },
    changePageAndUncheckItems(event, type) {
      this.dataTableKey += 1;

      if (type === 'PAGE') {
        this.page = event;
      } else {
        this.itemsPerPage = event;
      }

      this.selectedItems = [];
    },
    findFiles() {
      this.showFeedbackMessage();
      this.documentService.findFiles(this.protocolNumber);
    },
    findMovementFiles() {
      this.showFeedbackMessage();
      this.documentService.findMovementFiles(this.protocolNumber);
    },
    findProtocolFiles() {
      this.showFeedbackMessage();
      this.documentService.findProtocolFiles(this.protocolNumber);
    },
    showFeedbackMessage() {
      this.customizeSnackbarMessage('success', 'Sua solicitação foi recebida e esta sendo realizada.');
    },
    openDeleteSelectedDocuments() {
      if (this.selectedItems && this.selectedItems.length > 0) {
        this.titleDynamicDialog = 'Excluir Documentos';
        this.contentDynamicDialog = 'Tem certeza que deseja excluir os documentos selecionados?';
        this.typeDynamicAction = 'DELETE';
        this.showDialogDynamic = true;
      } else {
        this.customizeSnackbarMessage('error', 'Para realizar a exclusão é necessário selecionar pelo menos um documento.');
      }
    },
    openDownloadSelectedDocuments() {
      if (this.selectedItems && this.selectedItems.length > 0) {
        if (!this.validateOptionDownloadDocuments()) {
          this.titleDynamicDialog = 'Baixar Documentos';
          this.contentDynamicDialog = 'Tem certeza que deseja baixar documentos selecionados?';
          this.typeDynamicAction = 'DOWNLOAD';
          this.showDialogDynamic = true;
        } else {
          this.customizeSnackbarMessage('error', 'Não é possível realizar o download do documento.');
        }
      } else {
        this.customizeSnackbarMessage('error', 'Para realizar o download é necessário selecionar pelo menos um documento.');
      }
    },
    validateOptionDownloadDocuments() {
      return this.selectedItems.some((element) => element.name === null);
    },
    closeDynamicDialog() {
      this.showDialogDynamic = false;
    },
    async confirmDynamicDialog() {
      if (this.typeDynamicAction === 'DELETE') {
        await this.confirmDeleteDocuments();
      } else {
        await this.confirmDownloadDocuments();
      }
    },
    async confirmDeleteDocuments() {
      const queryString = this.actionDynamicModalAndPrepareQueryString();

      await this.documentService.DeleteSelectedDocuments(queryString).then(async () => {
        await this.findDocumentsByFilters();
        this.selectedItems = [];
        this.loadingActionDynamicModal = false;
        this.customizeSnackbarMessage('success', 'Os documentos selecionados foram excluídos com sucesso.', 3000);
      }).catch(() => {
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao tentar excluir os documentos selecionados.');
        this.loadingActionDynamicModal = false;
      }).finally(() => {
        this.loadingActionDynamicModal = false;
      });
    },
    async findDocumentsByFilters() {
      const queryString = this.isFileData ? `?fileDataId=${this.typeViewId}` : `?movementRecordId=${this.typeViewId}`;
      await this.documentService.FindDocumentByFilters(queryString).then((response) => {
        if (response && response.data) {
          this.documents = response.data;
        }
      }).catch(() => {
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao buscar os documentos');
      });
    },
    async confirmDownloadDocuments() {
      const queryString = this.actionDynamicModalAndPrepareQueryString();

      await this.documentService.DownloadFileDocuments(queryString).then((response) => {
        if (response) {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          const contentDispositionHeader = response.headers['content-disposition'];
          const name = this.validateNameInResponseHeaders(contentDispositionHeader);
          const nameDecoded = decodeURIComponent(name);

          fileLink.href = fileURL;
          fileLink.setAttribute('download', nameDecoded);
          document.body.appendChild(fileLink);
          fileLink.click();

          this.selectedItems = [];
          this.loadingActionDynamicModal = false;
          this.customizeSnackbarMessage('success', 'Download realizado com sucesso.', 3000);
        }
      }).catch(() => {
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao tentar efetuar o download.');
        this.loadingActionDynamicModal = false;
      }).finally(() => {
        this.loadingActionDynamicModal = false;
      });
    },
    validateNameInResponseHeaders(contentDispositionHeader) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDispositionHeader);
      let fileName = null;
      if (matches && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }

      let name;
      if (fileName) {
        name = fileName;
      } else if (this.selectedItems.length === 1) {
        const firstSelectedDocumentId = this.selectedItems[0].id;
        const matchingDocument = this.documents.find((element) => element.id === firstSelectedDocumentId);
        name = matchingDocument ? matchingDocument.name : null;
      } else {
        name = 'filename.zip';
      }

      return name;
    },
    actionDynamicModalAndPrepareQueryString() {
      this.closeDynamicDialog();
      this.loadingActionDynamicModal = true;
      const idDocuments = this.selectedItems.map((doc) => doc.id);
      return `?idDocuments=${idDocuments}`;
    },
    customizeSnackbarMessage(type, text, timeout) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
      this.snackbar.timeout = timeout || 10000;
    },
  },

  created() {
    this.formatter = new Formatters();
    this.documentService = new DocumentService();
    this.rules = new Rules();
  },
});
</script>

<style scoped>
.file-input-container {
  position: relative;
  background-color: #F3F6F9;
  cursor: pointer;
  z-index: -1;
}

.content {
  padding: 10px;
  cursor: pointer;
}

.custom-file-input {
  width: 100%;
  height: 70px;
  border: 1px dashed gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.file-input-label {
  text-align: center;
}

input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-input-inner {
  display: flex;
  align-items: center;
}

.file-input-inner-mr {
  margin-right: 20px;
  cursor: pointer;
}

.message-color {
  color: #3B495B;
  text-decoration: underline;
}

.dialog-card-upload {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}

.dialog-transition-enter-active,
.dialog-transition-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.dialog-transition-enter,
.dialog-transition-leave-to {
  opacity: 0;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-overlay v-progress-circular {
  margin-right: 8px;
}

@media screen and (max-width: 1600px) and (min-width: 1199px) {
  .dialog-card {
    width: 60%;
  }

  .file-input-inner {
    width: 60%;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .dialog-card {
    width: 70%;
  }

  .file-input-inner {
    width: 60%;
  }

  .custom-file-input {
    height: 100px;
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .dialog-card {
    width: 80%;
  }

  .file-input-inner {
    width: 60%;
  }

  .custom-file-input {
    height: 100px;
  }
}

@media screen and (max-width: 768px) and (min-width: 500px) {
  .dialog-card {
    width: 90%;
  }

  .file-input-inner {
    width: 60%;
  }

  .custom-file-input {
    height: 100px;
  }
}

@media screen and (max-width: 500px) {
  .dialog-card {
    width: 90%;
  }

  .file-input-inner {
    width: 60%;
  }

  .custom-file-input {
    height: 150px;
  }
}
</style>